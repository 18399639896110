body {
  margin: 0px;
}

h1 {
  font-size: 3em;
}

p {
  font-size: 1.1em;
}

a {
  color: #2f4454;
  text-decoration: none;
}

button {
  border: 1px solid black !important;
  padding: 5px 25px !important;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.flex-chips {
  line-height: 2.5;
  margin: 0.5;
}

.flex-chips .MuiChip-root {
  margin-right: 0.5em;
}

.introduction {
  color: black;
  padding-top: 15%;
  padding-bottom: 15%;
  font-size: 30px;
  letter-spacing: 0.3em;
  padding-left: 10%;
  padding-right: 10%;
  text-align: left;
}

.container {
  padding-top: 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-section {
  display: flex;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 5%;
}

.about-section .content {
  text-align: left;
  width: 50%;
}

.about-section .image-wrapper {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.animate-bottom {
  position: relative;
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s
}

@-webkit-keyframes animatebottom {
  from { bottom:-100px; opacity:0 } 
  to { bottom:0px; opacity:1 }
}

@keyframes animatebottom { 
  from{ bottom:-100px; opacity:0 } 
  to{ bottom:0; opacity:1 }
}

@media screen {
  @media (max-width: 567px) {
    #myProgress {
      position: fixed;
      left: 40%;
      top: 50%;
      border-radius: 25px 25px;
      background-color: #ddd;
    }

    .nav-left{
      padding-top: 20px;
      float:left;
    }

    .nav-left img {
      height: 35px
    }

    .nav-right {
      float:right;
    }

    .nav-right li {
      padding: 10px 15px;
      list-style:none;
      font-size:13px;
      color:white;
      width: 10%;
    }

    .nav-right ul {
      list-style-type: none;
      margin: 0px;
      padding: 0px;
      font-family: Verdana, sans-serif;
    }

    body {
      width: 100%;
    }

    .introduction {
      font-size: 18px;
      letter-spacing: 0.3em;
      padding-left: 5%;
      padding-right: 5%;
    }

    .introduction img {
      width: 60%;
    }

    .about {
      padding-left: 5%;
      padding-right: 5%;
      font-size: 12px;
    }

    .skills {
      font-size: 12px;
    }

    .experience {
      font-size: 12px;
      padding-left: 5%;
      padding-right: 5%;
    }

    .content-1 li {
      width: 7%;
    }
  
    .content-2 li {
      width: 15%;
    }
    
  }
}

@media (max-width:700px) {
  .about-section h1 {
    text-align: left;
  }

  .about-section p {
    text-align: left;
  }

   .container {
      display: initial;
  }

  .about-section {
    display: initial;
  }

  .about-section .content {
    text-align: center;
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
  }

  .about-section .image-wrapper {
    width: 100%;
  }
  
  .introduction {
    background-attachment: initial;
    padding-top: 35%;
    padding-bottom: 35%;
  }
}

.about-section .image-wrapper img{
  width: 80%;
  box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

@media (max-width:400px) {
  .introduction {
    padding-top: 40%;
    padding-bottom: 40%;
  }
}

@media (max-width:321px) {
  .introduction {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}