.projects-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 5%;
  padding-left: 10%;
  padding-right: 10%;
  text-align: left;
}

.projects-container .projects-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 50px;
  grid-gap: 50px;
}

.projects-container .projects-grid .project {
  text-align: left;
}

.project h2 {
  color: black;
}

.project h2:hover {
  text-decoration: underline;
}

.project-tools p {
  margin: 0.3em;
}

.zoom {
  border-radius: 5px;
  transition: transform .2s;
  margin: 0 auto;
}

.zoom:hover {
  transform: scale(1.05);
}

#project a {
  color: #ac3b61;
  font-weight: bold;
}

#project a:hover {
  color: #b1667f;
}

@media screen {
  @media (max-width: 700px) {
    .projects-container {
      display: block;
      padding-left: 5%;
      padding-right: 5%;
    }

    .projects-container .projects-grid {
      display: block;
    }

    .projects-container .projects-grid .project {
      padding-bottom: 5%;
    }
  }
}