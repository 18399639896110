html {
  scroll-behavior: smooth;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  display: grid;
  font-family: Verdana, sans-serif;
  text-align: center;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.349);
}

.flex-chips .zoom {
  transition: transform .2s;
}

.flex-chips .zoom:hover {
  background-color: aliceblue;
  transform: scale(1.15);
}

.items_wrapper {
  padding-left: 10%;
  padding-right: 10%;
  text-align: left;
  padding-top: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.video {
  position: relative;
  padding-bottom: 62.5%;
  height: 0;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen {
  @media (min-width: 1000px) {
    .items_wrapper {
      padding-left: 20%;
      padding-right: 20%;
    }
  }

  @media (max-width: 600px) {
    .nav-bar li {
      font-size: 0.8rem;
    }
  }
}