.skills-container {
  width: 80vw;
  display: flex;
  flex-direction: column;
  padding-bottom: 5%;
  text-align: left;
}

.skills-container .skills-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 50px;
  grid-gap: 50px;
}

.skills-container .skills-grid .skill {
  align-items: center;
  text-align: left;
}

@media screen {
  @media (max-width: 567px) {
    .skills-container h1 {
      padding-left: 5%;
      padding-right: 5%;
    }

    .skills-container {
      display: initial;
    }

    .skills-container .skills-grid {
      display: initial;
    }
    
    .skills-container .skills-grid .skill {
      padding-left: 5%;
      padding-right: 5%;
      padding-bottom: 5%;
    }
  }
}